import { Page } from '../templates/page';
import { Post } from '../templates/post';
import { ImageComponentFragment } from '../components/DynamicComponent/Image';
import { EmployeeComponentFragment } from '../components/DynamicComponent/Employee';
import { EmployeeCategoryComponentFragment } from '../components/DynamicComponent/EmployeeCategory';

const fixImage = (image: { url: string } | undefined) => {
  if (!image) return undefined;
  const { url } = image;
  return {
    url,
    imageFile: {
      id: url,
      childImageSharp: {
        fluid: {
          srcSet: url,
          src: url,
        },
      },
    },
  };
};

const patchLiveDataImagesForPage = (data: Page) => ({
  children: data.children
    ? data.children.map(child => ({
        ...child,
        image: child && child.image ? fixImage(child.image) : undefined,
      }))
    : undefined,
});

const patchLiveDataImagesForPost = (data: Post) => ({
  author: data.author
    ? {
        ...data.author,
        avatar: data.author && data.author.avatar ? fixImage(data.author.avatar) : undefined,
      }
    : undefined,
});

const patchLiveDataImages = <T extends Post | Page>(data: T): T => ({
  ...data,
  ...patchLiveDataImagesForPost(data as Post),
  ...patchLiveDataImagesForPage(data as Page),
  image: fixImage(data.image),
  content: data.content.map(c => ({
    ...c,
    image: fixImage((c as ImageComponentFragment).image),
    employee: (c as EmployeeComponentFragment).employee
      ? {
          ...(c as EmployeeComponentFragment).employee,
          avatar: fixImage((c as EmployeeComponentFragment).employee.avatar),
        }
      : undefined,
    category: (c as EmployeeCategoryComponentFragment).category
      ? {
          ...(c as EmployeeCategoryComponentFragment).category,
          employees: (c as EmployeeCategoryComponentFragment).category.employees.map(employee => ({
            ...employee,
            avatar: fixImage(employee.avatar),
          })),
        }
      : undefined,
  })),
});

export default patchLiveDataImages;
