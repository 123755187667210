import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { ImageFragment } from '../fragments/Image';
import TitleSmallImage from '../components/TitleSmallImage';
import DynamicComponent, { DynamicComponentFragment } from '../components/DynamicComponent';
import { fonts } from '../styles/variables';
import Section from '../components/Section';
import getPrettyDate from '../utils/getPrettyDate';
import PageParallax from '../components/PageParallax';
import sanitizeSrcSet from '../utils/sanitizeSrcSet';

export const query = graphql`
  query PostTemplateQuery($id: ID!) {
    strapi {
      post(id: $id) {
        id
        path
        title
        description
        image {
          ...ImageFragment
        }
        content {
          ...DynamicComponentFragment
        }
        published_date
        author {
          id
          name
          avatar {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

type Author = {
  id: string;
  name: string;
  avatar?: ImageFragment;
};

export type Post = {
  id: string;
  path: string;
  title: string;
  description: string;
  image?: ImageFragment;
  content: DynamicComponentFragment[];
  published_date?: string;
  author?: Author;
};

export interface PostTemplateQuery {
  strapi: {
    post: Post;
  };
}

const getAuthorStatement: (author?: Author, date?: string) => string | null = (author, date) => {
  if (author && date) return `Skrevet af ${author.name} d. ${getPrettyDate(date)}.`;
  if (author) return `Skrevet af ${author.name}.`;
  if (date) return `Skrevet d. ${getPrettyDate(date)}`;

  return null;
};

const PostTemplate: React.FC<{ data: PostTemplateQuery }> = ({
  data: {
    strapi: { post },
  },
}) => {
  const authorStatement = getAuthorStatement(post.author, post.published_date);

  return (
    <>
      <Helmet title={post.title} meta={[{ name: 'description', content: post.description }]} />
      <TitleSmallImage title={post.title} description={post.description} image={post.image} />
      <div css={{ position: 'relative' }}>
        <PageParallax seed={Number(post.id)} />
        <div css={{ position: 'relative', zIndex: 2 }}>
          <Section level={3} css={{ position: 'relative' }}>
            <div
              css={{
                display: 'flex',
                position: 'absolute',
                width: 300,
                height: 0,
                right: 0,
                top: -8,
                [`@media (max-width: 1200px)`]: {
                  width: 250,
                },
                [`@media (max-width: 874px)`]: {
                  width: 'auto',
                  height: 'auto',
                  position: 'relative',
                  maxWidth: 570,
                },
              }}
            >
              {post.author && post.author.avatar && post.author.avatar.imageFile.childImageSharp && (
                <img
                  alt={authorStatement as string}
                  src={post.author.avatar.imageFile.childImageSharp.fluid.src}
                  srcSet={sanitizeSrcSet(post.author.avatar.imageFile.childImageSharp.fluid.srcSet)}
                  sizes="300px"
                  css={{
                    objectFit: 'cover',
                    height: 30,
                    width: 30,
                    borderRadius: '100%',
                    marginRight: 10,
                    transform: 'translateY(-3px)',
                  }}
                />
              )}
              <p css={{ fontFamily: fonts.serif, fontStyle: 'italic' }}>{authorStatement}</p>
            </div>
          </Section>
          {post.content.map((props, index) => (
            <div
              key={index} // eslint-disable-line react/no-array-index-key
              css={{ marginTop: '0.6rem' }}
            >
              <DynamicComponent dynamicProps={props} index={index} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PostTemplate;
