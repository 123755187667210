const months = [
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december',
];

const getPrettyDate: (date: string) => string = date => {
  const [year, month, day] = date.split('-');

  return `${day}. ${months[Number(month) - 1]} ${year}`;
};

export default getPrettyDate;
