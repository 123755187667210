import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PostTemplate, { PostTemplateQuery, Post } from '../templates/post';
import patchLiveDataImages from '../utils/patchLiveDataImages';
import PreviewBanner from '../components/PreviewBanner';

export const query = gql`
  query PostTemplateQuery($key: ID!) {
    posts(where: { preview_key: $key }) {
      id
      path
      title
      description
      image {
        url
      }
      published_date
      author {
        id
        name
        avatar {
          url
        }
      }
      content {
        dynamicComponentType: __typename
        ... on ComponentContentAccordion {
          title
          items {
            content
            id
            title
            faq {
              id
              question
              answer
            }
          }
        }
        ... on ComponentContentEmployee {
          employee {
            id
            name
            position
            phone
            email
            avatar {
              url
            }
          }
        }
        ... on ComponentContentEmployeeCategory {
          id
          show_category_title
          category {
            id
            title
            employees {
              id
              name
              position
              phone
              email
              avatar {
                url
              }
            }
          }
        }
        ... on ComponentContentImage {
          caption
          image {
            url
          }
        }
        ... on ComponentContentText {
          text
        }
      }
    }
  }
`;

type PostPreviewTemplateQuery = Omit<PostTemplateQuery['strapi'], 'post'> & { posts: Post[] };

const PreviewPost: React.FC<{ location: Location }> = ({ location }) => {
  const { loading, data } = useQuery<PostPreviewTemplateQuery>(query, {
    variables: { key: location.search.replace('?key=', '') },
  });

  const post = data && data.posts.length > 0 && patchLiveDataImages(data.posts[0]);

  return !loading && data && post ? (
    <>
      <PreviewBanner url={location.origin + post.path} />
      <PostTemplate data={{ strapi: { ...data, post } }} />
    </>
  ) : null;
};

export default PreviewPost;
